// @flow

import  React, { Component } from "react"; 
import {  Card, FormTextInput, Button, Icon, Grid, Alert, Dimmer } from "tabler-react";
import defaultStrings from "../../data/LoginPage.strings";
import {Auth} from '../../Auth/Auth';
import { Crud_Catalogos } from "../../herramientas/Crud_Catalogos";
import { NavLink, withRouter } from "react-router-dom";

class LoginPage extends Component {
  constructor(props){
    super(props);
    this.state = {
      username: "",
      password: "",
   showmessage: false,
       message: "No message",
  loginMessage: null
    };
    this.props.history.push('/login');
  }
  componentWillMount=()=>{
    //alert(window.location);
  }
  changePassword = (event) =>{
    this.setState({password: event.target.value});
  }
  changeUsername = (event) =>{
    this.setState({username: event.target.value});
  }
  getLogin = () =>{
    this.setState({showmessage: false});
    Auth("login", this.state.username, this.state.password).then((res) => {
      if(res.token){
        this.setState({loginMessage: "Cargando perfiles .."});
        Crud_Catalogos("plataformasUsuariosPerfiles/username", "usuarios", "post","", {"username": this.state.username}, "", "currentUser").then((returnVal) => {
          this.setState({loginMessage: "Construyendo menu de opciones ..."});
          sessionStorage.setItem("usuario", JSON.stringify(returnVal[0].usuario));
          sessionStorage.setItem("perfil", JSON.stringify(returnVal[0].perfil));
          sessionStorage.setItem("plataforma", JSON.stringify(returnVal[0].plataforma));
          sessionStorage.setItem("currentUser", JSON.stringify(returnVal));
          Crud_Catalogos("bloque/usuario/"+returnVal[0].usuario.id+"/perfil/"+returnVal[0].perfil.id, "usuarios", "get","", "", "", "currentUser").then((bloques) => {
            let navBarItems= [];
            let modulosMonitoreoSubItems=[];
            let moduloDashboardSubItems=[];
            let moduloArrastreSubItems =[];
            let monitoreo= true;
            let Tracking= false;
            let Dashboard =  false;
            let Administracion = false;

            bloques.map((item, index)=>{
              console.log(item)
              switch (item.nombre) {
                case "Monitoreo_Terrestre":{
                  let isNav= false;
                  bloques.map((b, i)=>{ if(b.nombre === "Monitoreo_Naviero"){ isNav= true;}  return null;});
                  if(!isNav){//creamos menu modulo monitore aqui
                    modulosMonitoreoSubItems.push({ value:  "Monitoreo Todos", icon:"cast",                 to:"/monitoreo?mtipo=0",LinkComponent:withRouter(NavLink), useExact:true});
                    navBarItems.push({ value:"Modulos Monitoreo", icon:"cast", subItems: modulosMonitoreoSubItems });  
                  }
                  let asignado= false;
                  for(let opcion of navBarItems){
                    if(opcion.value === "Monitoreo Todos"){ asignado= true; }                    
                  }
                  if(!asignado){
                    //navBarItems.push({ value:  "Monitoreo Todos", icon:"layers",                 to:"/monitoreo",LinkComponent:withRouter(NavLink), useExact:true});               
                  }
                }break;
                case "Monitoreo_Naviero":{
                  let asignado= false;
                  for(let opcion of navBarItems){
                    if(opcion.value === "Monitoreo Todos"){ asignado= true; }                    
                  }
                  if(!asignado){
                    //navBarItems.push({ value:  "Monitoreo Todos", icon:"cast",                 to:"/monitoreo?mtipo=0",LinkComponent:withRouter(NavLink), useExact:true});                 
                    modulosMonitoreoSubItems.push({ value:  "Monitoreo Todos", icon:"cast",to:"/monitoreo?mtipo=0",LinkComponent:withRouter(NavLink), useExact:true});
                    Crud_Catalogos("movimientosTipo/usuarioCorredores/"+returnVal[0].usuario.id, "usuarios", "get","", {}, "", "movimientosTipo").then((movimientosTipo) => {
                      for( let mtipo of movimientosTipo){
                        switch (mtipo.id) {
                          case 1:
                            modulosMonitoreoSubItems.push({ value:  "Importación", icon:"cast",                 to:"/monitoreo?mtipo=1",LinkComponent:withRouter(NavLink), useExact:true}); 
                          break;
                          case 2:
                            modulosMonitoreoSubItems.push({ value:  "Exportación", icon:"cast",                 to:"/monitoreo?mtipo=2",LinkComponent:withRouter(NavLink), useExact:true}); 
                          break;
                          case 3:
                            modulosMonitoreoSubItems.push({ value:  "Importación Crossborder", icon:"cast",                 to:"/monitoreo?mtipo=3",LinkComponent:withRouter(NavLink), useExact:true}); 
                          break;
                          case 4:
                            modulosMonitoreoSubItems.push({ value:  "Exportación Crossborder", icon:"cast",                 to:"/monitoreo?mtipo=4",LinkComponent:withRouter(NavLink), useExact:true}); 
                          break;
                          default:
                            break;
                        }
                      }
                      navBarItems.push({ value:"Modulos Monitoreo", icon:"cast", subItems: modulosMonitoreoSubItems });   
                    });
                  }
                }break;
                case "Tracking": Tracking= true;   navBarItems.push({ value:  "Tracking Contenedor",   icon:"cast", to:"/tracking",LinkComponent:withRouter(NavLink)});
                  break;
                case "movimientos_nav_clien":
                  {
                    navBarItems.push({
                      value: "Servicios",
                      icon: "layers",
                      to: "/movimientos_nav_clien",
                      LinkComponent: withRouter(NavLink),
                      useExact: true,
                    });
                  }
                  break;
                case "dashboard_otd":
                //if(bloques.map(item => item.value == "Dashboard").length == 0){
                  navBarItems.push({ value:"Dashboards", icon:"cast", subItems: moduloDashboardSubItems });                ;
                 
                  moduloDashboardSubItems.push({
                    value: "Dashboard Otd",
                    icon: "layers",
                    to: "/dashboard_otd",
                    LinkComponent: withRouter(NavLink),
                    useExact: true,
                  });


                  moduloDashboardSubItems.push({
                    value: "KPI",
                    icon: "layers",
                    target:"_blank",
                    to: "https://app.powerbi.com/view?r=eyJrIjoiN2IzMTY3OGQtOGNjMC00NjkxLWE2MjEtNTJjYjA5ZWM4ZTJiIiwidCI6ImFiM2FmMjJkLWI4ZjEtNGE1Yy05M2NhLWNiMDVkZGE0OGFjZCJ9",
                    useExact: true,
                  });

               // }

                break;
                case "modulo_arrastre":
                  navBarItems.push({ value:"Modulo Arrastre", icon:"cast", subItems: moduloArrastreSubItems });                ;
                  moduloArrastreSubItems.push({
                    value: "Remolques",
                    icon: "layers",
                    to: "/remolques",
                    LinkComponent: withRouter(NavLink),
                    useExact: true,
                  });
                break;

                case "edicion_modulo_arrastre": 
                  navBarItems.push({ value:"Modulo Arrastre", icon:"cast", subItems: moduloArrastreSubItems });                ;
                  moduloArrastreSubItems.push({
                    value: "Logistica Remolque",
                    icon: "layers",
                    to: "/update_remolques",
                    LinkComponent: withRouter(NavLink),
                    useExact: true,
                  });
                
                break;
                case "Ordenes_Cliente":
                  navBarItems.push({
                      value: "Ordenes Cliente",
                      icon: "layers",
                      to: "/ordenescliente",
                      LinkComponent: withRouter(NavLink),
                      useExact: true,
                    });
                                 
                break;
                case "descarga_movimientos":
                  {
                    navBarItems.push({
                      value: "Descarga de Movimientos",
                      icon: "layers",
                      to: "/descarga_mov_maerks",
                      LinkComponent: withRouter(NavLink),
                      useExact: true,
                    });
                  }               
                break;
                case "descarga_movimientos_hellmann":
                  {
                    navBarItems.push({
                      value: "Descarga de Movimientos Hellmann",
                      icon: "layers",
                      to: "/descarga_mov_hellmann",
                      LinkComponent: withRouter(NavLink),
                      useExact: true,
                    });
                  }               
                break;
                case 'Administracion_Transportista':
                  navBarItems.push({
                    value: 'Administracion Transportista',
                    icon: 'layers',
                    to: '/administracion',
                    LinkComponent: withRouter(NavLink),
                    useExact: true,
                  });
                  //Administracion = true;
                break;

              case 'Monitoreo_OTD':              ;
              navBarItems.push({
                value: 'Monitoreo OTD',
                icon: 'layers',
                to: '/monitoreoOTD',
                LinkComponent: withRouter(NavLink),
                useExact: true,
              });

              break;
                default:
                  break;
              }
            });
            let redirect= "";
            console.log(navBarItems)
            setTimeout(function(){
              for( let item of navBarItems ){
                console.log(item.value)
                if(item.value === "Modulos Monitoreo"){         redirect="/monitoreo?mtipo=0";  monitoreo= true;  break;}
                if(item.value === "Importación"){               redirect="/monitoreoimpo";      monitoreo= true;  break;}
                if(item.value === "Exportación"){               redirect="/monitoreoexpo";      monitoreo= true;  break;}
                if(item.value === "Servicios") {                redirect = "/movimientos_nav_clien"; }
                if(item.value === "Ordenes Cliente") {          redirect = "/ordenescliente"; }
                if(item.value === "Descarga de Movimientos") {  redirect = "/descarga_mov_maerks"; }
                if(item.value === "Descarga de Movimientos Hellmann") {  redirect = "/descarga_mov_hellmann"; }
              }
             // navBarItems.sort(this.dynamicSort('value'));
              

              sessionStorage.setItem("navBarItems", JSON.stringify(navBarItems));
              if(Tracking){
                window.location.href = "/tracking";
              }else{
                if(redirect !== ""){       window.location.href = redirect; }else{ window.location.href = "/login"; }
              }
            }, 5000);            
          });
          
        }).catch(err =>{
          if( err.response ){
            console.log(err.response);
          }else{
            /**
             * Error
             */
            console.log("Error desconocido ....");
            console.log(err)
          }
          //window.location.href = "/login";
        });
      }else{
        this.setState({message: 'Usuario o contraseña incorrectos'});
        this.setState({showmessage: true});
        this.setState({username: ''});
        this.setState({password: ''});
        this.setState({loginMessage: null });
        
      }
    }).catch(err => { console.log(err);
                      this.setState({loginMessage: null });
                      this.setState({username: ''});
                      this.setState({password: ''});
                      this.setState({message: ""+err });
                      this.setState({showmessage: true});
                    })
  }
  screenWidth = () =>{
    //console.log("screen with: "+ window.screen.width)
    if(window.screen.width > 900){
      return 4;
    }else{
      return 0;
    }
  }
  _handleKeyDown=(e)=> {
    if (e.key === 'Enter') {
      this.getLogin();
    }
  }
  render(){
    const {    strings = {},
    
    } = this.props;
  return (
    <Grid.Col sm={12} lg={4} offset={this.screenWidth()} alignItems="center">
      <div className="p-4"></div>      
      <Card>
        <Card.Body>
        <div className="col-12 text-center">
        <img src="images/allone.svg" width="100" height="50" alt= "Skyangel" />
        </div>
        <div className="col-12 text-center" hidden={this.state.loginMessage}>
          {strings.title || defaultStrings.title}
        </div>
        <div hidden={!this.state.showmessage}>
          <Alert type="danger" icon="alert-triangle" //onDismissClick={ this.screenWidth }  isDismissible={true}
          >
            { this.state.message }
          </Alert>
        </div>

        <div hidden={!this.state.loginMessage} style={{height:"40vh"}}>
          <h1 className="mt-4">{this.state.loginMessage}</h1>
          <Dimmer active={true} loader>               
          </Dimmer>
        </div>
        <div hidden={this.state.loginMessage}>
          <FormTextInput
            name="username"
            label={defaultStrings.usernameLabel}
            placeholder={ defaultStrings.usernamePlaceholder}
            onChange={this.changeUsername}
            //onBlur={onBlur}
            value={this.state.username}
            //error={errors && errors.email}
          />
          <FormTextInput
            name="password"
            type="password"
            label={strings.passwordLabel || defaultStrings.passwordLabel}
            placeholder={ strings.passwordPlaceholder || defaultStrings.passwordPlaceholder}
            onKeyDown={this._handleKeyDown}
            onChange={this.changePassword}
            //onBlur={onBlur}
            value={this.state.password}
            //error={errors && errors.password}
          />
          <div className="col-12 text-right">
          <Button target="_blank" size="sm" RootComponent="a" color="primary" onClick= { () => this.getLogin()} >
              <span class="badge"><Icon link={true} name="user" />Login</span>
            </Button>
          </div>
        </div>        
        </Card.Body>
      </Card>
    </Grid.Col>
  );
        }
}

export default LoginPage;
