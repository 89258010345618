import React, { useState, useEffect } from "react";
import { Button, Form, Icon, Grid, Alert } from "tabler-react";

import SplitterLayout from 'react-splitter-layout';
import 'react-splitter-layout/lib/index.css';
import SiteWrapper from "../../../SiteWrapper.react";
import Select from "react-select";
import DateTimePicker from "react-datetime-picker";
import * as ACTIONS from '../../../store/actions/actions'
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Modal from 'react-modal';
import '../../navBarItems/MonitoreoPage.react.css';
import Crud_Catalogos from "../../../herramientas/Crud_Catalogos";
import ModalLay from "../../../components/ModalLay/ModalLay";
import { TransportistasForm } from "../../../Catalogos";
import LoadStore from "../../../store/LoadStore";
import { timeToDateFill } from "../../../herramientas/DateFormat";
import { esFecha } from "../../../herramientas/Forms";
import Cliente from "../Cliente";
import Transportista from "../Transportista";
import { TablePageable } from "../../../Helpers";
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import EntregasNavierasForm from "./EntregaNavierasForm";

const user = JSON.parse(sessionStorage.getItem("usuario"));

let guardando = false;
let MovimientosObject = {};
let estatusOperacion = [];
let estatusRutas = [];
let usuariosLogObject = {
  idBitacoraMonitoreo: 0,
  idUsuario: 0,
  idMovimiento: 0,
  accionFecha: "",
  descripcion: "",
  idPlataformaTipo: 1,
};

const technologiaOpciones = [
  { label: "4G Lite", value: "4G Lite" },
  { label: "5G", value: "5G" }
]

const MovimientosNavierosClientePage = (
  { privilegios_object, stor
  }
) => {
  const [solicitudes, setSolicitudes] = useState([]);

  const [plataformas, setPlataformas] = useState([]);
  const [gpsTipoColocaciones, setGpsTipoColocaciones] = useState([]);
  const [corredores, setCorredores] = useState([]);
  const [localidades, setLocalidades] = useState([]);
  const [clientesNavieros, setClientesNavieros] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [movimientosTipo, setMovimientosTipo] = useState([]);
  const [operacionTipos, setOperacionTipos] = useState([]);
  const [transportistas, setTransportistas] = useState([]);
  const [contenedoresTipo, setContenedoresTipo] = useState([]);
  const [ejecutivos, setEjecutivos] = useState([]);
  const [remolquesTipo, setRemolquesTipo] = useState([]);
  const [noContenedores, setNoContenedores] = useState(1);
  const [noPo, setNoPo] = useState(1);

  const [idPlataforma, setIdPlataforma] = useState({ value: 0, label: "Selecciona una plataforma" });
  const [gpsTipoColocacion, setGpsTipoColocacion] = useState({ value: 0, label: "Selecciona una opción" });
  const [idCorredor, setIdCorredor] = useState({ value: 0, label: "No se ha seleccionado plataforma" });
  const [localidad, setLocalidad] = useState({ value: 0, label: "No se ha seleccionado plataforma" });
  const [idClienteNaviero, setIdClienteNaviero] = useState({ value: 0, label: "No se ha seleccionado plataforma" });
  const [idCliente, setIdCliente] = useState({ value: 0, label: "No se ha seleccionado plataforma" });
  const [idMovimientoTipo, setIdMovimientoTipo] = useState({ value: 0, label: "No se ha seleccionado plataforma" });
  const [idOperacionTipo, setIdOperacionTipo] = useState({ value: 0, label: "No se ha seleccionado plataforma" });
  const [idTransportista, setIdTransportista] = useState({ value: 0, label: "No se ha seleccionado plataforma" });
  const [contenedorTipo, setContenedorTipo] = useState({ value: 0, label: "Selecciona el tipo de carga", data: [], });
  const [contenedorSize, setContenedorSize] = useState({ value: 0, label: "Selecciona el tamaño" });
  const [ejecutivo, setEjecutivo] = useState("");
  const [contenedor, setContenedor] = useState([]);
  const [po, setPo] = useState([]);
  const [booking, setBooking] = useState([]);
  const [citaProgramada, setCitaProgramada] = useState("");
  const [citaProgramadaTerminal, setCitaProgramadaTerminal] = useState("");
  const [destino, setDestino] = useState("");
  const [nota, setNota] = useState("");
  const [variablesObj, setVariablesObj] = useState({});
  const [variables, setVariables] = useState([]);
  const [entregasCarga, setEntregasCarga] = useState([]);
  const [entregasDesCarga, setEntregasDesCarga] = useState([]);

  const [unMovimientoPorContenedor, setUnMovimientoPorContenedor] = useState(true);//Se genera un movimiento por cada contenedor ingresado
  const [currentMovimiento, setCurrentMovimiento] = useState({});

  const [modalidadSeleccionada, setModalidadSeleccionada] = useState()
  const [modalidades, setModalidades] = useState([])
  const [sinCobertura, setSinCobertura] = useState(false);
  const [tecnologia, setTecnologia] = useState();
  const [codigoPostal, setCodigoPostal] = useState()

  const [hiddenPanel, setHiddenPanel] = useState(false);
  const [hiddenModals, setHiddenModals] = useState(false);
  const [crudbutonEnable, setCrudbutonEnable] = useState(true); // Se desabilita cuando se hace click en guardar
  const [nuevo, setNuevo] = useState(true);

  //varibles para destino
  const [estado, setEstado] = useState("");
  const [municipio, setMunicipio] = useState("");
  const [colonia, setColonia] = useState("");
  const [calle, setCalle] = useState("");
  const [numero, setNumero] = useState("");
  const [cp, setCP] = useState("");

  // Estado para el checkbox
  const [isChecked, setIsChecked] = useState(false); 
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked); 
  };

 

  useEffect(() => {
    // Solo concatenar si idPlataforma es 4
    if (idPlataforma.value && [4].includes(idPlataforma.value)) {
      // Concatenar los campos de dirección
      const destinoCom = `${estado}, ${municipio}, ${colonia}, ${calle}, ${numero}, ${cp}`;
      setDestino(destinoCom)
    } else {
      setDestino(""); // Limpiar el destino si idPlataforma no es 4
    }
  }, [idPlataforma, estado, municipio, colonia, calle, numero, cp]);


  //Encapsulamiento de la logica de recarga inicial 
  const initializeData = () => {
    getfuntion("get", "", "", "contenedorTipo", "contenedorTipo");
    getfuntion("get", "", "", "remolquesTipo", "remolquesTipo");
    getfuntion("get", "", "", "plataformas/usuario/" + user.id + "/bloque/564", "plataformas");//564 bloque movimientos_nav_clien
    //Obtener catalogo de modalidad de transporte
    getfuntion("get", "", "", "modalidades-transporte", "modalidad");
    getSolicitudes();
  };

  useEffect(() => {
    initializeData();
  }, []);

  useEffect(() => {
    if (idPlataforma.value) {
      if (nuevo) {
        //limpiamos el objeto movimiento
        MovimientosObject = {
          idPlataforma: plataformas.length === 1 ? idPlataforma.data : {},//requerido
          idNaviera: { id: "1", nombre: "Sin naviera" },//Default
          idOperacionTipo: [4].includes(idPlataforma.value) && idOperacionTipo.data ? idOperacionTipo.data : {},//requerido
          idMovimientoTipo: {},//requerido
          idTransportista: null,//opcional
          idCliente: null,//opcional
          idClienteNaviero: null,//opcional
          idCorredor: {},//requerido
          idLocalidad: null,//opcional transmodal
          destino: "",//opcional
          estado: "",//opcional
          municipio: "",//opcional
          colonia: "",//opcional
          calle: "",//opcional
          numero: "",//opcional
          cp: "",//requerido 
          contenedor: "",//requerido
          contenedorTipo: 1,
          contenedorSize: 0,
          booking: "",
          po: "",
          sello: "",
          vip: true,
          nota: "",
          citaProgramada: null,
          citaProgramadaTerminal: null,
          variables: {
            cuenta_espejo: {
              url: "",
              usuario: "",
              password: ""
            }
          },
          clienteS: null,
          transportistaS: null
        };
        setIdMovimientoTipo({ value: 0, label: "Cargando Tipos de movimiento" });
        setIdOperacionTipo({ value: 0, label: "Cargando Tipos de operación" });
        setIdCorredor({ value: 0, label: "Cargando corredores" });
        setLocalidad({ value: 0, label: "Cargando localidades" });
        setIdCliente({ value: 0, label: "Cargando Clientes" });
        setIdClienteNaviero({ value: 0, label: "Cargando Clientes Finales" });
        setIdTransportista({ value: 0, label: "Cargando Transportistas" });
        setContenedorSize({ value: 0, label: "Cargando Tipos de remolque" });
        if ([952].includes(idPlataforma.value)) {
          setEjecutivo({ value: 0, label: "Cargando ejecutivos" });
        } else {
          setEjecutivo("");
        }
      } else {
        MovimientosObject.idPlataforma = idPlataforma.data;
        for (let remTipo of remolquesTipo) {
          if (currentMovimiento.contenedorSize === remTipo.value) {
            setContenedorSize(remTipo);
            break;
          }
        }
        for (let contTipo of contenedoresTipo) {
          if (currentMovimiento.contenedorTipo === contTipo.value)
            setContenedorTipo(contTipo);
        }
      }
      if ([952, 4].includes(idPlataforma.value)) {
        setUnMovimientoPorContenedor(false);
      } else {
        setUnMovimientoPorContenedor(true);
      }
      getCatalogosPorPlataforma(idPlataforma.value);
    }
  }, [idPlataforma]);
  useEffect(() => {
    if (currentMovimiento.idSolicitud) {
      /**
       * Cargar catalogos
       */

      const mov = { ...currentMovimiento };
      setNuevo(false);
      if (mov.idPlataforma)//idPlataforma
        setIdPlataforma({ value: mov.idPlataforma.id, label: mov.idPlataforma.nombre, data: mov.idPlataforma, },);
      if (mov.idMovimientoTipo) {//idMovimientoTipo
        setIdMovimientoTipo({ value: mov.idMovimientoTipo.id, label: mov.idMovimientoTipo.nombre, data: mov.idMovimientoTipo },);
        getfuntion("get", "", "", "entregas_navieras/movimiento/" + mov.idMovimiento, "entregas");
      }
      if (mov.idCorredor)//idCorredor
        setIdCorredor({ value: mov.idCorredor.id, label: mov.idCorredor.nombre, },);
      if (mov.idLocalidad)//idLocalidad
        setLocalidad({ value: mov.idLocalidad.id, label: mov.idLocalidad.nombre, },);
      if (mov.idTransportista)//idTransportista
        setIdTransportista({ value: mov.idTransportista.id, label: mov.idTransportista.nombre, data: mov.idTransportista },);
      if (mov.idCliente)//idCliente
        setIdCliente({ value: mov.idCliente.id, label: mov.idCliente.nombre, data: mov.idCliente, },);
      if (mov.idClienteNaviero)//idClienteNaviero
        setIdClienteNaviero({ value: mov.idClienteNaviero.id, label: mov.idClienteNaviero.nombre, data: mov.idClienteNaviero, },);
      if (mov.idOperacionTipo)//idOperacionTipo
        setIdOperacionTipo({ value: mov.idOperacionTipo.id, label: mov.idOperacionTipo.nombre, data: mov.idOperacionTipo, },);
      if (mov.idGgpsTipoColocacion)//idGgpsTipoColocacion
        setGpsTipoColocacion({ value: mov.idGgpsTipoColocacion.id, label: mov.idGgpsTipoColocacion.nombre, data: mov.idGgpsTipoColocacion, },);
      if (idPlataforma.value && [4].includes(idPlataforma.value)) {
        //Datos de destino
        //`${estado}, ${municipio}, ${colonia}, ${calle}, ${numero}, ${cp}`
        setEstado(currentMovimiento.destino.split(",")[0] || " ");
        setMunicipio(currentMovimiento.destino.split(",")[1] || " ");
        setColonia(currentMovimiento.destino.split(",")[2] || " ");
        setCalle(currentMovimiento.destino.split(",")[3] || " ");
        setNumero(currentMovimiento.destino.split(",")[4] || " ");
        setCP(currentMovimiento.destino.split(",")[5] || " ");
      } else {
        setDestino(mov.destino ? mov.destino : "");
      }
      setNota(mov.nota ? mov.nota : "");
      setBooking(mov.booking ? mov.booking : "");
      //Contenedor
      let contenedorTemp = [];
      for (let i = 0; i < mov.contenedor.split(";").length; i++) {
        contenedorTemp.push(mov.contenedor.split(";")[i])
      }
      setContenedor(contenedorTemp);
      setNoContenedores(mov.contenedor.split(";").length);
      //po
      let poTemp = [];
      if (mov.po) {
        for (let i = 0; i < mov.po.split(";").length; i++) {
          poTemp.push(mov.po.split(";")[i])
        }
      }
      setPo(poTemp);
      setNoPo(mov.po ? mov.po.split(";").length : 1);
      if (mov.citaProgramada != null) {
        setCitaProgramada(new Date(mov.citaProgramada));
      }
      if (mov.citaProgramadaTerminal != null) {
        setCitaProgramadaTerminal(new Date(mov.citaProgramadaTerminal));
      }
      setModalidadSeleccionada(mov.modalidadTransporte)
      setCodigoPostal(mov.codigoPostal)
      MovimientosObject = mov;
    }
  }, [currentMovimiento]);

  const isEnable = (Accion) => {
    //let borrar= "clientes_borrar";

    let actualizar = "movimientos_editar";
    let agregar = "movimientos_agregar";

    let actualizarCliente = "clientes_editar";
    let agregarCliente = "clientes_agregar";
    let actualizarTransportista = "transportistas_editar";
    let agregarTransportista = "transportistas_agregar";
    var privilegios = privilegios_object;
    var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0; i < privilegios.length; i++) {
      if (Accion === "actualizarCliente") {
        if (privilegios[i].clave === actualizarCliente) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio === privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
      if (Accion === "agregarCliente") {
        if (privilegios[i].clave === agregarCliente) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio === privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }

      if (Accion === "actualizarTransportista") {
        if (privilegios[i].clave === actualizarTransportista) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio === privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
      if (Accion === "agregarTransportista") {
        if (privilegios[i].clave === agregarTransportista) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio === privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }

      //if(Accion === "borrar"){ if( privilegios[i].clave === borrar){ if(perfil.privilegios.filter(word => word.idPrivilegio === privilegios[i].id).length > 0 ){return true}}}
      if (Accion === "actualizar") {
        if (privilegios[i].clave === actualizar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio === privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
      if (Accion === "agregar") {
        if (privilegios[i].clave === agregar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio === privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
    }
    return false;
  };
  const reset = () => {
    //limpiamos el objeto movimiento
    MovimientosObject = {
      idPlataforma: plataformas.length === 1 ? idPlataforma.data : {},//requerido 
      idNaviera: { id: "1", nombre: "Sin naviera" },//Default
      idOperacionTipo: [4].includes(idPlataforma.value) && idOperacionTipo.data ? idOperacionTipo.data : {},//requerido
      idMovimientoTipo: {},//requerido
      idTransportista: null,//opcional
      idCliente: null,//opcional
      idClienteNaviero: null,//opcional
      idCorredor: {},//requerido
      idLocalidad: null,//opcional transmodal
      destino: "",//opcional
      contenedor: "",//requerido
      contenedorTipo: 1,
      contenedorSize: 0,
      booking: "",
      po: "",
      sello: "",
      vip: true,
      nota: "",
      citaProgramada: null,
      citaProgramadaTerminal: null,
      variables: {
        cuenta_espejo: {
          url: "",
          usuario: "",
          password: ""
        }
      },
      clienteS: null,
      transportistaS: null,
      entregas: [],
    };
    /**
     * Limpiamos entregas
     */
    setCodigoPostal(undefined)
    setModalidadSeleccionada(undefined)
    setTecnologia(undefined)
    setEntregasCarga([]);
    setEntregasDesCarga([]);
    setSinCobertura(undefined)
    /**
     * limpiamos los estados
     */
    //limpiamos variables
    let variablesObjTemp = { ...variablesObj };
    for (let v of variables) {//v.tipo, v.longitud, v.nombre, v.etiqueta
      switch (v.tipo) {
        case "String":
          MovimientosObject.variables[v.nombre] = "";
          if ([952].includes(idPlataforma.value) && v.nombre === "ejecutivo_transmodal") {
            variablesObjTemp[v.nombre] = { value: 0, label: "Selecciona un ejecutivo" };
          } else {
            variablesObjTemp[v.nombre] = "";
          }
          break
        case "Number":
          MovimientosObject.variables[v.nombre] = 0;
          variablesObjTemp[v.nombre] = 0;
          break;
        case "DateTime":
          MovimientosObject.variables[v.nombre] = null;
          variablesObjTemp[v.nombre] = null;
        case "Any":
          switch (v.nombre) {
            case "cuenta_espejo":
              MovimientosObject.variables.cuenta_espejo.url = "";
              MovimientosObject.variables.cuenta_espejo.usuario = "";
              MovimientosObject.variables.cuenta_espejo.password = "";
              variablesObjTemp["cuenta_espejo.url"] = "";
              variablesObjTemp["cuenta_espejo].usuario"] = "";
              variablesObjTemp["cuenta_espejo].password"] = "";
              break;
            default:
              break;
          }
          break;
        case "Boolean":
          MovimientosObject.variables[v.nombre] = false;
          variablesObjTemp[v.nombre] = false;
          break;
        default:
          break;
      }
    }
    setVariablesObj(variablesObjTemp);
    setVariables([]);
    //Se limpian catalogos requeridos
    if (plataformas.length === 1) {
      setIdPlataforma(plataformas[0]);
    } else {
      setIdPlataforma({ value: 0, label: "Selecciona una plataforma" });
    }
    setGpsTipoColocacion({ value: 0, label: "Selecciona una opción" });
    setIdCorredor({ value: 0, label: "No se ha seleccionado plataforma" });
    setLocalidad({ value: 0, label: "No se ha seleccionado plataforma" });
    setIdClienteNaviero({ value: 0, label: "No se ha seleccionado plataforma" });
    setIdCliente({ value: 0, label: "No se ha seleccionado plataforma" });
    setIdMovimientoTipo({ value: 0, label: "No se ha seleccionado plataforma" });
    if (![4].includes(idPlataforma.value)) {
      setIdOperacionTipo({ value: 0, label: "No se ha seleccionado plataforma" });
    }
    setIdTransportista({ value: 0, label: "No se ha seleccionado plataforma" });
    setContenedorTipo({ value: 0, label: "Selecciona el tipo de carga", data: [], });
    setContenedorSize({ value: 0, label: "Selecciona el tamaño" });
    setEjecutivo("");
    setBooking("");
    setContenedor([]);
    setPo([]);
    setCitaProgramada("");
    setCitaProgramadaTerminal("");
    setDestino("");
    setEstado("");
    setMunicipio("");
    setColonia("");
    setCalle("");
    setNumero("");
    setCP("");
    setNota("");
    //setVariablesObj({});    
    setNoContenedores(1);
    setNoPo(1);
    setCrudbutonEnable(true);
    guardando = false;
    setNuevo(true);
    setCurrentMovimiento({})
  }
  const getfuntion = (metodo = "get", obj = [], id = "", catalogo = "cliente", stateVar = "clientes", hiddenModl = "") => {
    return Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, [])
      .then((returnVal) => {
        switch (metodo) {
          case "get":
            if (stateVar === "variables") {
              setVariables(returnVal);
              let variablesObjTemp = {};
              for (let v of returnVal) {
                switch (v.tipo) {
                  case "String":
                    variablesObjTemp[v.nombre] = !nuevo ? currentMovimiento.variables[v.nombre] : "";
                    MovimientosObject.variables[v.nombre] = !nuevo ? currentMovimiento.variables[v.nombre] ? currentMovimiento.variables[v.nombre] : "" : "";
                    break
                  case "Number":
                    variablesObjTemp[v.nombre] = !nuevo ? currentMovimiento.variables[v.nombre] : 0;
                    MovimientosObject.variables[v.nombre] = !nuevo ? currentMovimiento.variables[v.nombre] ? currentMovimiento.variables[v.nombre] : 0 : 0;
                    break;
                  case "DateTime":
                    variablesObjTemp[v.nombre] = !nuevo ? currentMovimiento.variables[v.nombre] : null;
                    MovimientosObject.variables[v.nombre] = !nuevo ? currentMovimiento.variables[v.nombre] ? currentMovimiento.variables[v.nombre] : null : null;
                  case "Any":
                    switch (v.nombre) {
                      case "cuenta_espejo":
                        variablesObjTemp["cuenta_espejo.url"] = !nuevo ? currentMovimiento.variables[v.nombre].url : "";
                        variablesObjTemp["cuenta_espejo.usuario"] = !nuevo ? currentMovimiento.variables[v.nombre].usuario : "";
                        variablesObjTemp["cuenta_espejo.password"] = !nuevo ? currentMovimiento.variables[v.nombre].password : "";
                        //MovimientosObject.variables.cuenta_espejo.url= !nuevo? currentMovimiento.variables[v.nombre].url?currentMovimiento.variables[v.nombre].url: "": "";
                        //MovimientosObject.variablescuenta_espejo.usuario= !nuevo? currentMovimiento.variables[v.nombre].usuario?currentMovimiento.variables[v.nombre].usuario: "": "";
                        //MovimientosObject.variablescuenta_espejo.password= !nuevo? currentMovimiento.variables[v.nombre].password?currentMovimiento.variables[v.nombre].password: "": "";
                        break;
                      default:
                        break;
                    }
                    break;
                  case "Boolean":
                    variablesObjTemp[v.nombre] = !nuevo ? currentMovimiento.variables[v.nombre] : false;
                    MovimientosObject.variables[v.nombre] = !nuevo ? currentMovimiento.variables[v.nombre] ? currentMovimiento.variables[v.nombre] : false : false;
                    break;
                  default:
                    break;
                }
              }

              setVariablesObj(variablesObjTemp);
            }

            if (stateVar === "solicitudes_cliente") {
              let solicitudesTmp = [];
              for (let v of returnVal.sort((a, b) => b.idSolicitud - a.idSolicitud)) {
                v.variables = JSON.parse(v.variables);
                v.clienteS = JSON.parse(v.clienteS);
                v.transportistaS = JSON.parse(v.transportistaS);
                solicitudesTmp.push(v);
              }
              setSolicitudes(solicitudesTmp);
            }
            if (stateVar === "entregas") {
              //Solo se consideran 2 entregas              
              //carga o consolidado
              setEntregasCarga(returnVal.filter(e => e.carga).sort((a, b) => a.orden - b.orden));
              //descarga o desconsolidado
              setEntregasDesCarga(returnVal.filter(e => !e.carga).sort((a, b) => a.orden - b.orden));
            }
            if (stateVar === "plataformas" && returnVal) {
              getfuntion("get", "", "", "gpsTipoColocacion", "gpsTipoColocacion");
              let plataformasTmp = [];
              for (let p of returnVal)
                plataformasTmp.push({ value: p.id, label: p.nombre, data: p });
              setPlataformas(plataformasTmp);
              if (plataformasTmp.length === 1)
                changePlataformas(plataformasTmp[0]);
            }
            if (stateVar === "gpsTipoColocacion") {
              let gpsTipoColocacionTemp = [];
              var {id} = JSON.parse(sessionStorage.getItem("plataforma"));
              for (let gt of returnVal) {
                if (gt.id !== 6) {
                  if (id == 4) {
                    gpsTipoColocacionTemp.push({
                      value: gt.id,
                      label: (gt.nombre === "Respaldo" ? "Doble GPS" : gt.nombre && gt.nombre === "Principal" ? "Principal/Portátil/Recuperable" : gt.nombre),
                      data: gt,
                    });
                  } else {
                    gpsTipoColocacionTemp.push({
                      value: gt.id,
                      label: gt.nombre,
                      data: gt,
                    });
                  }
                }
              };
              setGpsTipoColocaciones(gpsTipoColocacionTemp);
              if (nuevo)
                setGpsTipoColocacion({ value: 0, label: "Selecciona una opción" });
            }
            if (stateVar === "corredores") {
              let corredoresTemp = [];
              returnVal.map((corredor, index) => {
                corredoresTemp.push({
                  value: corredor.id,
                  label: corredor.nombre,
                  data: corredor,
                });
                if ([952].includes(idPlataforma.value) && corredor.id === 1) {
                  setIdCorredor(corredoresTemp[index]);
                  MovimientosObject.idCorredor = corredoresTemp[index].data;
                }
              });
              setCorredores(corredoresTemp);

              if (nuevo && ![952].includes(idPlataforma.value)) {
                setIdCorredor({ value: 0, label: "Selecciona un corredor" });
              }
            }
            if (stateVar === "ejecutivos") {
              const mov = { ...currentMovimiento };
              let ejecutivosTemp = [];
              returnVal.map((e) => {
                ejecutivosTemp.push({ value: e.id, label: e.nombre, data: e, });
                if (!nuevo && mov.variables && mov.variables.ejecutivo && e.nombre === mov.variables.ejecutivo) {
                  setEjecutivo(ejecutivosTemp[ejecutivosTemp.length - 1]);
                }
              });
              setEjecutivos(ejecutivosTemp);
              if (nuevo) {
                setEjecutivo({ value: 0, label: "Selecciona un ejecutivo" });
              }
            }
            if (stateVar === "localidades") {
              let localidadesTemp = [];
              returnVal.map((l, index) =>
                localidadesTemp.push({ value: l.id, label: l.nombre, data: l, })
              );
              setLocalidades(localidadesTemp);
              if (nuevo) {
                setLocalidad({ value: 0, label: "Selecciona una localidad" });
              }
            }
            if (stateVar === "contenedorTipo") {
              let ContTipoTemp = [];
              for (let contTipo of returnVal) {
                if (nuevo) {
                  if (contTipo.id === 1)
                    setContenedorTipo({ value: contTipo.id, label: contTipo.nombre, data: contTipo });
                } else {
                  if (currentMovimiento.contenedorTipo === contTipo.id)
                    setContenedorTipo({ value: contTipo.id, label: contTipo.nombre, data: contTipo });
                }
                ContTipoTemp.push({ value: contTipo.id, label: contTipo.nombre, data: contTipo });
              };
              setContenedoresTipo(ContTipoTemp);
            }
            if (stateVar === "remolquesTipo") {
              let remolquesTipoTemp = [];
              for (let remTipo of returnVal) {
                if (!nuevo && currentMovimiento.contenedorSize === remTipo.idRemolque)
                  setContenedorSize({ value: remTipo.idRemolque, label: remTipo.nombre, data: remTipo, });
                remolquesTipoTemp.push({ value: remTipo.idRemolque, label: remTipo.nombre, data: remTipo, });
              }
              setRemolquesTipo(remolquesTipoTemp);
              if (nuevo)
                setContenedorSize({ value: 0, label: "Selecciona una tamaño de remolque" });
            }
            if (stateVar === "clientes") {
              let clientesTemp = [];
              if (returnVal.length > 0) {
                returnVal.map((client, index) =>
                  clientesTemp.push({
                    value: client.id,
                    label: client.nombre,
                    data: client,
                  })
                );
                setClientes(clientesTemp);
                if (nuevo) {
                  setIdCliente({ value: 0, label: "Selecciona un cliente" });
                }
              } else {
                setIdCliente({ value: 0, label: "No hay clientes" });
              }
            }
            if (stateVar === "clientesNavieros") {
              let clientesNav = [];
              if (returnVal.length > 0) {
                returnVal.map((client, index) =>
                  clientesNav.push({
                    value: client.id,
                    label: client.nombre,
                    data: client,
                  })
                );
                setClientesNavieros(clientesNav);
                if (nuevo) {
                  setIdClienteNaviero({ value: 0, label: "Selecciona un cliente" });
                }
              } else {
                setIdClienteNaviero({ value: 0, label: "No hay clientes" });
              }
            }
            if (stateVar === "operacionesTipo") {
              let operacionesTemp = [];
              returnVal.map((op, index) => {
                operacionesTemp.push({
                  value: op.id,
                  label: op.nombre,
                  data: op,
                });;
                if (op.id === 2 && nuevo && [4].includes(idPlataforma.value)) {
                  setIdOperacionTipo(operacionesTemp[index]);
                  MovimientosObject.idOperacionTipo = operacionesTemp[index].data;
                }
              });
              if (nuevo && ![4].includes(idPlataforma.value)) {
                setIdOperacionTipo({ value: 0, label: "Selecciona un tipo de operación" });
              }
              setOperacionTipos(operacionesTemp);
            }
            if (stateVar === "movimientosTipo") {
              let movimientoTiposTemp = [];
              returnVal.map((mt, index) =>
                movimientoTiposTemp.push({
                  value: mt.id,
                  label: mt.nombre,
                  data: mt,
                })
              );
              setMovimientosTipo(movimientoTiposTemp);
              if (nuevo) {
                setIdMovimientoTipo({ value: 0, label: "Selecciona un Tipo de movimiento" },);
              }
            }
            if (stateVar === "transportistas") {
              let transportistasTemp = [];
              for (let t of returnVal) {
                transportistasTemp.push({
                  value: t.id,
                  label: t.nombre,
                  data: t,
                });
              }
              setTransportistas(transportistasTemp);
              if (nuevo) {
                setIdTransportista({ value: 0, label: "Selecciona un Transportistas", },);
              }
            }
            if (stateVar === "modalidad") {
              setModalidades(returnVal)
            }
            if (stateVar === "coberturas") {
              setSinCobertura(!returnVal)
            }
            break;
          case "post":
            if (stateVar === "solicitudes_cliente") {
              if (returnVal.idSolicitud) {
                NotificationManager.info(
                  "Se ha generado la solicitud con el siguiente identificador: " + returnVal.idSolicitud, "Nuevo"
                );
              } else {
                NotificationManager.error(
                  "Parece que hubo un error al procesar la información, verifica a la izquierda que se haya genereado la solicitud",
                  "Nuevo");
              }
              getSolicitudes();
            }
            if (stateVar === "clientes") {
              if (returnVal.id) {
                getfuntion(
                  "post",
                  {
                    idPlataforma: idPlataforma.value,
                    idCliente: returnVal.id,
                  },
                  "",
                  "plataformasClientes",
                  "plataformasClientes"
                );
                NotificationManager.info(
                  "Se ha agregado el cliente al catlogo general( " +
                  returnVal.nombre +
                  " )",
                  "Nuevo"
                );
                let clientesTemp = clientes;
                clientesTemp.push({
                  value: returnVal.id,
                  label: returnVal.nombre,
                  data: returnVal,
                });
                MovimientosObject.idCliente = returnVal;
                setClientes(clientesTemp);

                setIdCliente({ value: returnVal.id, label: returnVal.nombre, data: returnVal, },);
              }
            }
            if (stateVar === "plataformasClientes") {
              if (returnVal.idPlataforma) {
                NotificationManager.info(
                  "El cliente ahora pertenece a la plataforma( " +
                  idPlataforma.value +
                  " )",
                  "Nuevo"
                );
              }
            }
            if (stateVar === "navieraMovimientos" && returnVal.id) {
              usuariosLogObject.idBitacoraMonitoreo = 1;
              usuariosLogObject.idUsuario = user.id;
              usuariosLogObject.idMovimiento = returnVal.id;
              usuariosLogObject.idPlataformaTipo = 1;
              usuariosLogObject.accionFecha = timeToDateFill(
                new Date().getTime()
              );
              usuariosLogObject.descripcion = "Nuevo Movimiento";
              Crud_Catalogos(
                "usuariosLog",
                "bitacoraMov",
                "post",
                "",
                usuariosLogObject
              );
            }
            break;
          case "put":
            if (stateVar === "solicitudes_cliente") {
              if (returnVal.idSolicitud) {
                NotificationManager.info(
                  "Se ha actualizado la solicitud con el siguiente identificador: " + returnVal.idSolicitud, "Actualización"
                );
              } else {
                NotificationManager.error(
                  "Parece que hubo un error al procesar la información, verifica a la izquierda que se haya actualizado la solicitud",
                  "Actualización");
              }
              getSolicitudes();
            }
            if (stateVar === "clientes") {
              if (returnVal.id) {
                NotificationManager.info(
                  "Se han agregado las modificaciones del cliente al catlogo general( " +
                  returnVal.nombre +
                  " )",
                  "Modificación"
                );
                let clientesTem = clientes;
                for (let item of clientesTem) {
                  if (item.id === returnVal.id) {
                    item = returnVal;
                  }
                }
                setClientes(clientesTem);
              }
            }
            if (stateVar === "navieraMovimientos" && returnVal.id) {
              usuariosLogObject.idBitacoraMonitoreo = 1;
              usuariosLogObject.idUsuario = user.id;
              usuariosLogObject.idMovimiento = returnVal.id;
              usuariosLogObject.idPlataformaTipo = 1;
              usuariosLogObject.accionFecha = timeToDateFill(
                new Date().getTime()
              );
              usuariosLogObject.descripcion =
                "Actualización de Movimiento";
              Crud_Catalogos(
                "usuariosLog",
                "bitacoraMov",
                "post",
                "",
                usuariosLogObject
              );
            }
            break;
          case "delete":
          default:
            break;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getfuntionTrans = (metodo = "get", obj = [], id = "", catalogo = "transportistas", stateVar = "transportistas", hiddenModl = "") => {
    Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, [])
      .then((returnVal) => {
        switch (metodo) {
          case "post":
            if (stateVar === "transportistas") {
              if (returnVal.id) {
                getfuntionTrans(
                  "post",
                  {
                    plataforma: currentMovimiento.idPlataforma,
                    transportista: returnVal,
                  },
                  "",
                  "plataformasTransportistas",
                  "plataformasTransportistas"
                );
                NotificationManager.info(
                  "Se ha agregado el transportista al catlogo general( " +
                  returnVal.nombre +
                  " )",
                  "Nuevo"
                );
                let transportistasTemp = transportistas;
                transportistasTemp.push({
                  value: returnVal.id,
                  label: returnVal.nombre,
                  data: returnVal,
                });
                MovimientosObject.idTransportista = returnVal;
                setTransportistas(transportistasTemp);
                setIdTransportista({ value: returnVal.id, label: returnVal.nombre, data: returnVal, },);
              }
            }
            if (stateVar === "plataformasTransportistas") {
              if (returnVal.plataforma) {
                NotificationManager.info(
                  "El transportista ahora pertenece a la plataforma( " +
                  returnVal.plataforma.nombre +
                  " )",
                  "Nuevo"
                );
              }
            }
            break;
          case "put":
            if (stateVar === "transportistas") {
              if (returnVal.id) {
                NotificationManager.info(
                  "Se han agregado las modificaciones del transportista al catalogo general( " +
                  returnVal.nombre +
                  " )",
                  "Modificación"
                );
                let transportistasTem = transportistas;
                for (let item of transportistasTem) {
                  if (item.id === returnVal.id) {
                    item = returnVal;
                  }
                }
                setTransportistas(transportistasTem);
                setIdTransportista({ value: returnVal.id, label: returnVal.nombre, data: returnVal, },);
              }
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => console.log(err));
  };
  const getCatalogosPorPlataforma = (idPlt) => {
    getfuntion(
      "get",
      "",
      "",
      "variables/" + idPlt,
      "variables"
    );
    getfuntion(
      "get",
      "",
      "",
      "corredores/cli_usuario/" + idPlt,
      "corredores"
    );
    getfuntion(
      "get",
      "",
      "",
      "localidades/cli_usuario/" + idPlt,
      "localidades"
    );
    getfuntion(
      "get",
      "",
      "",
      "cliente/cli_usuario/" + idPlt,
      "clientes"
    );
    getfuntion(
      "get",
      "",
      "",
      "clientes_navieros/cli_usuario/" + idPlt,
      "clientesNavieros"
    );
    getfuntion(
      "get",
      "",
      "",
      "operacionesTipo/plataforma/" + idPlt,
      "operacionesTipo"
    );
    getfuntion(
      "get",
      "",
      "",
      "movimientosTipo/cli_usuario/" + idPlt,
      "movimientosTipo"
    );
    // Trae los transportistas
    getfuntion(
      "get",
      "",
      "",
      "transportistas/cli_usuario/" + idPlt,
      "transportistas"
    );

    if ([952].includes(idPlt)) {
      getfuntion(
        "get",
        "",
        "",
        "ejecutivos/plataforma/" + idPlt,
        "ejecutivos"
      );
    }
  }

  const enableSave = () => {
    // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
    // console.log(
    //   crudbutonEnable,
    //   idPlataforma,
    //   idCliente,
    //   idMovimientoTipo,
    //   idCorredor,
    //   idTransportista,
    //   contenedorSize,
    //   contenedorTipo,
    //   contenedor,
    //   noContenedores,
    //   destino,
    //   po,
    //   noPo,
    //   idOperacionTipo,
    //   ejecutivo
    // );
    // console.log("<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<");
    /*
    console.log("impo: "+ ([952].includes(idPlataforma.value) && [1, 3].includes(idMovimientoTipo.value)
    ? (esFecha(citaProgramada) && esFecha(citaProgramadaTerminal)) && new Date(citaProgramadaTerminal).getTime() < new Date(citaProgramada).getTime()
    : true))
    console.log("expo: "+ ([952].includes(idPlataforma.value) && [2, 4].includes(idMovimientoTipo.value)
    ? (esFecha(citaProgramada) && esFecha(citaProgramadaTerminal)) && new Date(citaProgramada).getTime() < new Date(citaProgramadaTerminal).getTime()
    : true))*/
    if (
      idPlataforma.value
      && idCliente.value
      && idMovimientoTipo.value
      && ([952].includes(idPlataforma.value) ? true : destino.length)
      && idCorredor.value
      && ([952, 1315].includes(idPlataforma.value)? true: localidad.value)//localidad.value &&
      && idTransportista.value
      && contenedorSize.value
      && contenedorTipo.value
      && (contenedor.length === Number(noContenedores)) && !contenedor.filter(c => c.length === 0).length
      && idOperacionTipo.value
      && ([952].includes(idPlataforma.value) ? (po.length === Number(noPo)) && !po.filter(p => p.length === 0).length : true)
      && ([952].includes(idPlataforma.value) ? ejecutivo.value : true)
      && ([952].includes(idPlataforma.value) && [1, 3].includes(idMovimientoTipo.value)
        ? (esFecha(citaProgramada) && esFecha(citaProgramadaTerminal)) && new Date(citaProgramadaTerminal).getTime() < new Date(citaProgramada).getTime()
        : true)
      && ([952].includes(idPlataforma.value) && [2, 4].includes(idMovimientoTipo.value)
        ? (esFecha(citaProgramada) && esFecha(citaProgramadaTerminal)) && new Date(citaProgramada).getTime() < new Date(citaProgramadaTerminal).getTime()
        : true)
      && gpsTipoColocacion && gpsTipoColocacion.value !== 0
      && ([4].includes(idPlataforma.value) ? ((gpsTipoColocacion.value === 2 && Boolean(tecnologia)) || gpsTipoColocacion.value !== 2) : true)
      && ([4].includes(idPlataforma.value) ? modalidadSeleccionada : true)
      && ([4].includes(idPlataforma.value) ? estado && municipio && colonia && calle && numero && cp : true)
      && ([4].includes(idPlataforma.value) ? codigoPostal : true)
      && ([4].includes(idPlataforma.value) ? codigoPostal && !sinCobertura || gpsTipoColocacion.label === "Desechable" : true)
    ) {
      return false;
    } else {
      return true;
    }
  };
  const reordenarEntregas = (entregasCargaTemp, entregasDesCargaTemp) => { 
    let i = 1;
    for (let e of entregasCargaTemp) {
      e.orden = i;
      i++;
    }
    for (let e of entregasDesCargaTemp) {
      e.orden = i;
      i++;
    }
    return { carga: entregasCargaTemp, descarga: entregasDesCargaTemp };
  }
  const setEntregasAMov = (entregaObj) => {
    let entregasCargaTemp = [...entregasCarga];
    let entregasDesCargaTemp = [...entregasDesCarga];
    if (entregaObj.carga) {
      if (entregaObj.idEntrega && entregasCargaTemp.length) {
        for (let i = 0; i < entregasCargaTemp.length; i++) {
          if (entregasCargaTemp[i].orden === entregaObj.orden)
            entregasCargaTemp[i] = entregaObj;
        }
      } else {
        entregasCargaTemp.push(entregaObj);
        const entregasOrdenadas = reordenarEntregas([...entregasCargaTemp], [...entregasDesCargaTemp]);
        entregasCargaTemp = entregasOrdenadas.carga;
        entregasDesCargaTemp = entregasOrdenadas.descarga;
      }
    } else {
      if (entregaObj.idEntrega && entregasDesCargaTemp.length) {
        for (let i = 0; i < entregasDesCargaTemp.length; i++) {
          if (entregasDesCargaTemp[i].orden === entregaObj.orden)
            entregasDesCargaTemp[i] = entregaObj;
        }
      } else {
        entregasDesCargaTemp.push(entregaObj)
        const entregasOrdenadas = reordenarEntregas([...entregasCargaTemp], [...entregasDesCargaTemp]);
        entregasCargaTemp = entregasOrdenadas.carga;
        entregasDesCargaTemp = entregasOrdenadas.descarga;
      }
    }
    setEntregasCarga(entregasCargaTemp);
    setEntregasDesCarga(entregasDesCargaTemp);
    setTimeout(() => {
      setHiddenModals(false);
    }, 200);
  }

  const guardarMovimiento = () => {
    let movTemp = { ...MovimientosObject };

    if (idPlataforma.value && [4].includes(idPlataforma.value)) {
      movTemp.destino = `${estado}, ${municipio}, ${colonia}, ${calle}, ${numero}, ${cp}`;
    }
    // Actualizar los datos de movTemp
    movTemp.idPlataforma = idPlataforma.data;
    movTemp.modalidadTransporte = modalidadSeleccionada;
    movTemp.codigoPostal = codigoPostal;
    movTemp.idGgpsTipoColocacion = gpsTipoColocacion.data;
    if (tecnologia) {
      movTemp.tecnologia = tecnologia.value;
    }

    // Guardar cada contenedor y PO individualmente si es necesario
    if (unMovimientoPorContenedor && noContenedores === noPo) {
      for (let i = 0; i < noContenedores; i++) {
        // Crear una copia de movTemp para cada contenedor y PO
        const tempMovimiento = { ...movTemp };

        if ([270].includes(movTemp.idMovimientoTipo.id)) {
          tempMovimiento.entregas = [...entregasCarga, ...entregasDesCarga];
        }
        if (contenedor[i] && contenedor[i].length) {
          tempMovimiento.contenedor = contenedor[i];
          tempMovimiento.po = po[i];
          tempMovimiento.variables = JSON.stringify(tempMovimiento.variables);
          tempMovimiento.clienteS = JSON.stringify(tempMovimiento.clienteS);
          tempMovimiento.transportistaS = JSON.stringify(tempMovimiento.transportistaS);

          if (!nuevo) {
            crud_put(tempMovimiento);
          } else {
            crud_post(tempMovimiento);
          }
        }
      }
    } else {
      // Concatenar todos los contenedores y POs en una cadena si no se guardan individualmente
      let contenedorTemp = "";
      let poTemp = "";
      for (let i = 0; i < noContenedores; i++) {
        if (contenedor[i] && contenedor[i].length) {
          contenedorTemp = contenedorTemp + (contenedorTemp.length ? ";" : "") + contenedor[i];
        }
      }
      for (let i = 0; i < noPo; i++) {
        if (po[i] && po[i].length) {
          poTemp = poTemp + (poTemp.length ? ";" : "") + po[i];
        }
      }
      if ([270].includes(movTemp.idMovimientoTipo.id)) {
        movTemp.entregas = [...entregasCarga, ...entregasDesCarga];
      }
      movTemp.contenedor = contenedorTemp;
      movTemp.po = poTemp;
      movTemp.variables = JSON.stringify(movTemp.variables);
      movTemp.clienteS = JSON.stringify(movTemp.clienteS);
      movTemp.transportistaS = JSON.stringify(movTemp.transportistaS);

      if (!nuevo) {
        crud_put(movTemp);
      } else {
        crud_post(movTemp);
      }
    }
    // Cerrar el formulario y reiniciar el estado
    setIsFormOpen(false);
    reset();
    initializeData();
  };


  //************ Combos ***********************
  const changePlataformas = (event) => {

    console.log(event)
    setIdPlataforma(event);
  };

  const changeCliente = (event) => {
    MovimientosObject.idCliente = event.data;
    setIdCliente(event);
  };
  const changeClienteNaviero = (event) => {
    MovimientosObject.idClienteNaviero = event.data;
    setIdClienteNaviero(event);
  };

  const changeContenedorTipo = (event) => {
    MovimientosObject.contenedorTipo = event.value;
    setContenedorTipo(event);
  };
  const changeTecnologia = (event) => {
    setTecnologia(event)
  };
  const changeContenedorSize = (event) => {
    MovimientosObject.contenedorSize = event.value;
    setContenedorSize(event);
  };
  const changeDestino = (event) => {
    MovimientosObject.destino = event.target.value;
    setDestino(event.target.value);
  };
  const changeNota = (event) => {
    if (event.target.value.length <= 2000) {
      MovimientosObject.nota = event.target.value;
      setNota(event.target.value);
    }
  };

  const changeCorredor = (event) => {
    MovimientosObject.idCorredor = event.data;
    setIdCorredor(event);
  };
  const changeEjecutivo = (event) => {
    MovimientosObject.variables.ejecutivo = event.label;
    setEjecutivo(event);
  };
  const changeLocalidad = (event) => {
    MovimientosObject.idLocalidad = event.data;
    setLocalidad(event);
  };
  const changeMovimientoTipo = (event) => {
    MovimientosObject.idMovimientoTipo = event.data;
    setIdMovimientoTipo(event);
  };
  const changeOperacionTipo = (event) => {
    MovimientosObject.idOperacionTipo = event.data;
    setIdOperacionTipo(event);
  };
  const changeTransportista = (event) => {
    MovimientosObject.idTransportista = event.data;
    setIdTransportista(event);
  };
  //Campos Normales

  const onChangeDate = (date) => {
    console.log("error", date)
    MovimientosObject.citaProgramada = timeToDateFill(
      new Date(date).getTime()
    );
    date != null
      ? setCitaProgramada(date)
      : setCitaProgramada(date);
  };
  const onChangeCitaProgramadaTerminal = (date) => {
    MovimientosObject.citaProgramadaTerminal = timeToDateFill(
      new Date(date).getTime()
    );
    date != null
      ? setCitaProgramadaTerminal(date)
      : setCitaProgramadaTerminal(date);
  };
  const crud_put = (obj) => {
    if (!guardando) {
      guardando = true;
      getfuntion(
        "put",
        obj,
        "",
        "solicitudes_cliente?enviarCorreo=" + ([952].includes(idPlataforma.value) ? true : false),
        "solicitudes_cliente"
      );
      setCrudbutonEnable(false);
    }
  }

  const crud_post = (obj) => {
    if (!guardando) {
      guardando
      getfuntion(
        "post",
        obj,
        "",
        "solicitudes_cliente?enviarCorreo= " + ([952].includes(idPlataforma.value) ? true : false),
        "solicitudes_cliente"
      );
      setCrudbutonEnable(false);
    }
  }

  useEffect(() => {
    if (codigoPostal) {
      if (codigoPostal && codigoPostal.length === 5) {
        getfuntion(
          "get",
          "",
          "",
          "coberturas/checar-codigo-postal?zipCode=" + codigoPostal,
          "coberturas"
        )
      }
    }

  }, [codigoPostal])

  /**
   * Privilegio activo
   */
  const estaPrivilegioActivo = (clave) => {
    var privilegios = privilegios_object; var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0; i < privilegios.length; i++) {
      if (privilegios[i].clave === clave) {
        if (perfil.privilegios.filter(word => word.idPrivilegio === privilegios[i].id).length > 0) { return true } else { return false }
      }
    }
  }
  const getForm = (tipo, longitud, nombre, etiqueta) => {
    let variablesObjTemp = { ...variablesObj };
    switch (tipo) {
      case "String":
      return (
          <>
          <Grid.Col md={4}>
            <Form.Group
              label={
                [952].includes(idPlataforma.value) && nombre === "ejecutivo_transmodal" ?
                  <div>
                    {etiqueta}
                    <label className="font-weight-bold text-danger">*</label>
                  </div> : etiqueta
              }
            >
              {[952].includes(idPlataforma.value) && nombre === "ejecutivo_transmodal" ? (
                <Select
                  value={ejecutivo}
                  onChange={changeEjecutivo}
                  options={ejecutivos}
                />
              ) : (
                <Form.Input
                  name={nombre}
                  type="text"
                  value={variablesObj[nombre]}
                  disabled={nombre === "referencia_facturacion" && isChecked}
                  style={{
                    backgroundColor:
                      nombre === "referencia_facturacion" && isChecked ? "#e9ecef" : "white",
                  }}
                  onChange={(e) => {
                    if (e.target.value.length <= longitud) {
                      const nombreTemp = nombre.split(".");
                      variablesObjTemp[nombre] = e.target.value;
                      setVariablesObj(variablesObjTemp);
                      if (1 === nombreTemp.length)
                        MovimientosObject.variables[nombreTemp[0]] = e.target.value;
                      if (2 === nombreTemp.length)
                        MovimientosObject.variables[nombreTemp[0]][nombreTemp[1]] =
                          e.target.value;
                      if (3 === nombreTemp.length)
                        MovimientosObject.variables[nombreTemp[0]][nombreTemp[1]][
                          nombreTemp[2]
                        ] = e.target.value;
                    }
                  }}
                  maxlength="30"
                />
              )}
              {[4].includes(idPlataforma.value) && nombre === "referencia_facturacion" ? (
                <>
                  <label className="font-weight-bold">
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={(e) => {
                        const isCheckedValue = e.target.checked;
                        handleCheckboxChange(isCheckedValue); // Cambia el estado de `isChecked`

                        // Limpia el input si el checkbox está activo
                        if (isCheckedValue) {
                          variablesObjTemp[nombre] = ""; // Limpia el valor del campo
                          setVariablesObj({ ...variablesObjTemp }); // Actualiza el estado
                          MovimientosObject.variables[nombre] = ""; // Actualiza el objeto global
                        }
                      }}
                    />
                    N/A
                  </label>
                  <div className="text-danger mt-1 font-weight-bold">
                    Favor de considerar que con este dato/número de referencia se emitirá su factura.
                  </div>
                </>
              ) : null}
            </Form.Group>
          </Grid.Col>        
          </>
      );

      case "Number":
        return (
          <Grid.Col md={4}>
            <Form.Group label={etiqueta}>
              {" "}
              <Form.Input
                name={nombre}
                type="number"
                value={variablesObj[nombre]}
                onChange={(e) => {
                  if (e.target.value.length <= longitud) {
                    const nombreTemp = nombre.split(".");
                    variablesObjTemp[nombre] = e.target.value;
                    setVariablesObj(variablesObjTemp);
                    if (1 === nombreTemp.length)
                      MovimientosObject.variables[nombreTemp[0]] = e.target.value;
                    if (2 === nombreTemp.length)
                      MovimientosObject.variables[nombreTemp[0]][nombreTemp[1]] = e.target.value;
                    if (3 === nombreTemp.length)
                      MovimientosObject.variables[nombreTemp[0][nombreTemp[1]][nombreTemp[2]]] = e.target.value;
                  }
                }}
                maxlength="30"
              />
            </Form.Group>{" "}
          </Grid.Col>);
      case "DateTime":
        return (
          <Grid.Col md={4}>
            <Form.Group label={
              [1].includes(idPlataforma.value)? (
                <>
                  {etiqueta}<label className="text-danger mt-1 font-weight-bold">*</label>
                </>
                ):etiqueta}>
              <DateTimePicker
                format="y-MM-dd h:mm:ss a"
                value={variablesObj[nombre]}
                onChange={(e) => {
                  const nombreTemp = nombre.split(".");
                  variablesObjTemp[nombre] = e;
                  setVariablesObj(variablesObjTemp);
                  if (1 === nombreTemp.length)
                    MovimientosObject.variables[nombreTemp[0]] = e ? timeToDateFill(new Date(e).getTime()) : e;
                  if (2 === nombreTemp.length)
                    MovimientosObject.variables[nombreTemp[0]][nombreTemp[1]] = e ? timeToDateFill(new Date(e).getTime()) : e;
                  if (3 === nombreTemp.length)
                    MovimientosObject.variables[nombreTemp[0][nombreTemp[1]][nombreTemp[2]]] = e ? timeToDateFill(new Date(e).getTime()) : e;
                }}
              />
            </Form.Group>
          </Grid.Col>)
      case "Any":
        switch (nombre) {
          case "cuenta_espejo":
            if (gpsTipoColocacion.value === 7) {
              let colsTemp = [];
              colsTemp.push(getForm("String", 400, "cuenta_espejo.url", "Url (Cuenta espejo)"));
              colsTemp.push(getForm("String", 200, "cuenta_espejo.usuario", "Usuario (Cuenta espejo)"));
              colsTemp.push(getForm("String", 200, "cuenta_espejo.password", "Password (Cuenta espejo)"));
              return colsTemp;
            } else {
              return null;
            }
          default:
            break;
        }
        break;
      case "Boolean":
        return (
          <Grid.Col md={4}>
            <Form.Group label={etiqueta}>
              {idPlataforma.value && [4].includes(idPlataforma.value) ? (
                <>
                  {/* Switch para GPS por Contenedor */}
                  <Form.Switch
                    name={nombre}
                    checked={!variablesObj[nombre]}
                    label="GPS por Contenedor"
                    onChange={(e) => {
                      const nombreTemp = nombre.split(".");
                      variablesObjTemp[nombre] = !e.target.checked;
                      setVariablesObj(variablesObjTemp);
                      if (1 === nombreTemp.length)
                        MovimientosObject.variables[nombreTemp[0]] = !e.target.checked;
                      if (2 === nombreTemp.length)
                        MovimientosObject.variables[nombreTemp[0]][nombreTemp[1]] = !e.target.checked;
                      if (3 === nombreTemp.length)
                        MovimientosObject.variables[nombreTemp[0][nombreTemp[1]][nombreTemp[2]]] = !e.target.checked;
                    }}
                  />
                  {/* Switch para GPS por Full */}
                  <br />
                  <Form.Switch
                    name={nombre}
                    checked={variablesObj[nombre]}
                    label="GPS por Full"
                    onChange={(e) => {
                      const nombreTemp = nombre.split(".");
                      variablesObjTemp[nombre] = e.target.checked;
                      setVariablesObj(variablesObjTemp);
                      if (1 === nombreTemp.length)
                        MovimientosObject.variables[nombreTemp[0]] = e.target.checked;
                      if (2 === nombreTemp.length)
                        MovimientosObject.variables[nombreTemp[0]][nombreTemp[1]] = e.target.checked;
                      if (3 === nombreTemp.length)
                        MovimientosObject.variables[nombreTemp[0][nombreTemp[1]][nombreTemp[2]]] = e.target.checked;
                    }}
                  />
                </>
              ) : (
                // Switch original para Full y Sencillo
                <Form.Switch
                  name={nombre}
                  checked={variablesObj[nombre]}
                  label={variablesObj[nombre] ? "Full" : "Sencillo"}
                  onChange={(e) => {
                    const nombreTemp = nombre.split(".");
                    variablesObjTemp[nombre] = e.target.checked;
                    setVariablesObj(variablesObjTemp);
                    if (1 === nombreTemp.length)
                      MovimientosObject.variables[nombreTemp[0]] = e.target.checked;
                    if (2 === nombreTemp.length)
                      MovimientosObject.variables[nombreTemp[0]][nombreTemp[1]] = e.target.checked;
                    if (3 === nombreTemp.length)
                      MovimientosObject.variables[nombreTemp[0][nombreTemp[1]][nombreTemp[2]]] = e.target.checked;
                  }}
                />
              )}
            </Form.Group>
          </Grid.Col>

        )
      default:
        break;
    }
  }

  const getNoPo = () => {
    let noPoCols = [];
    for (let i = 0; i < noPo; i++) {
      noPoCols.push(
        <Grid.Col md={4} key={i} >
          <Form.Group label={
            <div>
              {"OP(" + (i + 1) + ")"}
              <label className="font-weight-bold text-danger">*</label>
            </div>}>
            {" "}
            <Form.Input
              name={"po" + i}
              type="text"
              value={po[i] ? po[i] : ""}
              onChange={(e) => {
                let poTemp = [...po];
                poTemp[i] = e.target.value;
                setPo(poTemp);
              }}
            />
          </Form.Group>{" "}
        </Grid.Col>
      );
    }
    return noPoCols;
  }
  const getNoContenedores = () => {
    let noCotenedorCols = [];
    for (let i = 0; i < noContenedores; i++) {
      noCotenedorCols.push(
        <Grid.Col md={4} key={i} >
          <Form.Group label={
            <div>
              {"Contenedor/Caja (" + (i + 1) + ")"}
              <label className="font-weight-bold text-danger">*</label>
            </div>}>
            {" "}
            <Form.Input
              name={"contenedor" + i}
              type="text"
              value={contenedor[i] ? contenedor[i] : ""}
              onChange={(e) => {
                let contenedorTemp = [...contenedor];
                contenedorTemp[i] = e.target.value;
                setContenedor(contenedorTemp);
              }}
            />
          </Form.Group>{" "}
        </Grid.Col>
      );
    }
    return noCotenedorCols;
  }
  const getSolicitudes = () => getfuntion("get", "", "", "solicitudes_cliente", "solicitudes_cliente");
  const setCliente = (c) => {
    MovimientosObject.clienteS = c;
    MovimientosObject.idCliente = null;
    setIdCliente({ value: "temparal", label: c.nombre });
  }//transportistaS
  const setTransportista = (t) => {
    MovimientosObject.transportistaS = t;
    MovimientosObject.idTransportista = null;
    setIdTransportista({ value: "temparal", label: t.nombre });
  }

  const [isFormOpen, setIsFormOpen] = useState(false);

  const openNewForm = () => {
    setIsFormOpen(true)


  }

  const closeNewForm = () => {
    setIsFormOpen(false)
    reset()
    initializeData();
  }

  return (
    <>

      <Modal
        isOpen={isFormOpen}
        contentLabel="Example Modal"
        className="Modal"
        style={{
          overlay: { display: "block" },
          content: {
            position: "relative",
            top: "60px",
            width: 1200,
            height: "auto",
            margin: "auto",
          }
        }}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title"> </h4>

          </div>
          <div className="modal-body">
            <div>
              <div className="container bg-light mb-2" style={{ height: "100%" }}>
                {[4].includes(idPlataforma.value) && <h5>Tipo de Operación: {idOperacionTipo.label}</h5>}
                <Grid.Row>
                  <Grid.Col md={12} xl={12}>
                    <Grid.Row>
                      <Grid.Col md={4} lg={4}>
                        <Form.Group label="Plataforma">
                          <Select
                            value={idPlataforma}
                            onChange={changePlataformas}
                            options={plataformas}
                            isDisabled={plataformas.length === 1}
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col md={4}>
                        <Form.Group label={
                          <div>
                            Cliente
                            <label className="font-weight-bold text-danger">*</label>
                          </div>}>
                          <Grid.Row>
                            <Grid.Col>
                              <Select
                                value={idCliente}
                                onChange={changeCliente}
                                options={clientes}
                                isLoading={
                                  clientes.length === 0 ? true : false
                                }
                              />
                              {idCliente.value > 0 ? (
                                <p>{idCliente.data.domicilio}</p>
                              ) : null}
                            </Grid.Col>
                            {idPlataforma.value > 0 &&
                              (isEnable("actualizarCliente") ||
                                isEnable("agregarCliente")) ? (
                              <Grid.Col md={2} lg={2}>
                                {isEnable("agregarCliente") ? (
                                  <span className="float-right">
                                    <ModalLay
                                      tamanio="85%"
                                      title={"Nuevo"}
                                      name={
                                        <span
                                          className="fa fa-plus ml-2"
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title={
                                            "Nuevo cliente(Antes de crear valida que no exista)"
                                          }
                                        ></span>
                                      }
                                      formulario={Cliente}
                                      datosForm={{
                                        accion: "nuevo",
                                        elementos: idCliente.data,
                                        setCliente: setCliente
                                      }}
                                    />
                                  </span>
                                ) : null}
                                {false && isEnable("actualizarCliente") ? (
                                  <span className="float-right">
                                    {idCliente.value > 0 ? (
                                      <ModalLay
                                        tamanio="85%"
                                        title="Modificar"
                                        name={
                                          <span
                                            className="fa fa-edit"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title={
                                              "Modificar (" +
                                              idCliente.label +
                                              ")"
                                            }
                                          ></span>
                                        }
                                        formulario={Cliente}
                                        datosForm={{
                                          accion: "modificar",
                                          elementos: idCliente.data,
                                          setCliente: setCliente
                                        }}
                                      />
                                    ) : null}
                                  </span>
                                ) : null}
                              </Grid.Col>
                            ) : null}
                          </Grid.Row>
                        </Form.Group>
                      </Grid.Col>
                      {false &&
                        <Grid.Col md={4}>
                          <Form.Group label="Cliente Final.">
                            <Select
                              value={idClienteNaviero}
                              onChange={changeClienteNaviero}
                              options={clientesNavieros}
                              isLoading={
                                clientesNavieros.length === 0
                                  ? true
                                  : false
                              }
                            />
                          </Form.Group>
                        </Grid.Col>}
                      <Grid.Col md={4}>
                        <Form.Group label={
                          <div>
                            Tipo de Movimiento
                            <label className="font-weight-bold text-danger">*</label>
                          </div>}>
                          <Select
                            value={idMovimientoTipo}
                            onChange={changeMovimientoTipo}
                            options={movimientosTipo}
                            isLoading={
                              movimientosTipo.length === 0 ? true : false
                            }
                          />
                        </Form.Group>
                      </Grid.Col>
                      {idPlataforma.value && ![4].includes(idPlataforma.value) &&
                        <Grid.Col md={4}>
                          <Form.Group label={
                            <div>
                              Operación Tipo
                              <label className="font-weight-bold text-danger">*</label>
                            </div>}>
                            <Select
                              value={idOperacionTipo}
                              onChange={changeOperacionTipo}
                              options={operacionTipos}
                              isLoading={
                                operacionTipos.length === 0 ? true : false
                              }
                            />
                          </Form.Group>
                        </Grid.Col>
                      }
                      {[270].includes(idMovimientoTipo.value) ?
                        <Grid.Col md={4}>
                          <Grid.Row>
                            <div className="row">
                              <div className="col">
                                <label className="font-weight-bold">Origen(Consolidación)</label>
                                <label className="font-weight-bold text-danger">*</label>
                              </div>
                              <div className="col">
                                {!hiddenModals ?
                                  <ModalLay
                                    tamanio="85%"
                                    title={"Nuevo"}
                                    name={
                                      <span>
                                        <span
                                          className="fa fa-plus ml-2 btn"
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title={
                                            "Agregar origen"
                                          }
                                        ></span>
                                      </span>
                                    }
                                    formulario={EntregasNavierasForm}
                                    datosForm={{
                                      entrega: null,
                                      movimiento: currentMovimiento,
                                      idPlataforma: idPlataforma,
                                      setEntregasAMov: setEntregasAMov,
                                      carga: true,
                                      setHiddenModals,
                                    }}
                                  />
                                  : null}
                              </div>
                            </div>
                            {
                              entregasCarga.map((e, index) =>
                                <Grid.Col key={e} md={12} >
                                  <Form.Group label={
                                    <div className="row">
                                      <div className="col-3 p-0">
                                        <div className="row">
                                          <div className="col-6">
                                            <div className="row">
                                              <div className="col-12 p-0" hidden={index < 1}>
                                                <i className="fa fa-caret-up btn" style={{ "font-size": "16px" }}
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title={"Estableser orden " + (index + 1)}
                                                  onClick={() => {
                                                    let entregasCargaTemp = [...entregasCarga];
                                                    let entregasDesCargaTemp = [...entregasDesCarga];
                                                    const entregaTemp = entregasCargaTemp[index - 1];
                                                    entregasCargaTemp[index - 1] = e;
                                                    entregasCargaTemp[index] = entregaTemp;
                                                    const entregasOrdenadas = reordenarEntregas([...entregasCargaTemp], [...entregasDesCargaTemp]);
                                                    entregasCargaTemp = entregasOrdenadas.carga;
                                                    entregasDesCargaTemp = entregasOrdenadas.descarga;
                                                    setEntregasCarga(entregasCargaTemp);
                                                    setEntregasDesCarga(entregasDesCargaTemp);
                                                  }}></i>
                                              </div>
                                              <div className="col-12 p-0" hidden={index >= entregasCarga.length - 1}>
                                                <i className="fa fa-caret-down btn" style={{ "font-size": "16px" }}
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title={"Estableser orden " + (index + 2)}
                                                  onClick={() => {
                                                    let entregasCargaTemp = [...entregasCarga];
                                                    let entregasDesCargaTemp = [...entregasDesCarga];
                                                    const entregaTemp = entregasCargaTemp[index + 1];
                                                    entregasCargaTemp[index + 1] = e;
                                                    entregasCargaTemp[index] = entregaTemp;
                                                    const entregasOrdenadas = reordenarEntregas([...entregasCargaTemp], [...entregasDesCargaTemp]);
                                                    entregasCargaTemp = entregasOrdenadas.carga;
                                                    entregasDesCargaTemp = entregasOrdenadas.descarga;
                                                    setEntregasCarga(entregasCargaTemp);
                                                    setEntregasDesCarga(entregasDesCargaTemp);
                                                  }}></i>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-6">{e.orden}</div>
                                        </div>
                                      </div>
                                      <div className="col-1 p-3">
                                        {!hiddenModals ?
                                          <ModalLay
                                            tamanio="85%"
                                            title="Modificar"
                                            name={
                                              <span
                                                className="fa fa-edit btn"
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title={
                                                  "Modificar (" +
                                                  e.destino +
                                                  ")"
                                                }
                                              ></span>
                                            }
                                            formulario={EntregasNavierasForm}
                                            datosForm={{
                                              entrega: e,
                                              movimiento: currentMovimiento,
                                              idPlataforma: idPlataforma,
                                              setEntregasAMov: setEntregasAMov,
                                              carga: true,
                                              setHiddenModals,
                                            }}
                                          />
                                          : null}
                                      </div>
                                      <div className="col p-0">{e.destino}</div>
                                    </div>}>
                                  </Form.Group>
                                </Grid.Col>
                              )
                            }
                          </Grid.Row>
                        </Grid.Col>
                        : null}
                      {[270].includes(idMovimientoTipo.value) ?
                        <Grid.Col md={4}>
                          <Grid.Row>
                            <div className="row">
                              <div className="col">
                                <label className="font-weight-bold">Destino(Desconsolidación)</label>
                                <label className="font-weight-bold text-danger">*</label>
                              </div>
                              <div className="col">
                                {!hiddenModals ?
                                  <ModalLay
                                    tamanio="85%"
                                    title={"Nuevo"}
                                    name={
                                      <span>
                                        <span
                                          className="fa fa-plus ml-2"
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title={
                                            "Agregar destino"
                                          }
                                        ></span>
                                      </span>
                                    }
                                    formulario={EntregasNavierasForm}
                                    datosForm={{
                                      entrega: null,
                                      movimiento: currentMovimiento,
                                      idPlataforma: idPlataforma,
                                      setEntregasAMov: setEntregasAMov,
                                      carga: false,
                                      setHiddenModals,
                                    }}
                                  />
                                  : null}
                              </div>
                            </div>
                            {
                              entregasDesCarga.map((e, index) =>
                                <Grid.Col md={12}>
                                  <Form.Group label={
                                    <div className="row">
                                      <div className="col-3 p-0">
                                        <div className="row">
                                          <div className="col-6">
                                            <div className="row">
                                              <div className="col-12" hidden={index < 1}>
                                                <i className="fa fa-caret-up btn" style={{ "font-size": "16px" }}
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title={"Estableser orden " + (index + 1)}
                                                  onClick={() => {
                                                    let entregasCargaTemp = [...entregasCarga];
                                                    let entregasDesCargaTemp = [...entregasDesCarga];
                                                    const entregaTemp = entregasDesCargaTemp[index - 1];
                                                    entregasDesCargaTemp[index - 1] = e;
                                                    entregasDesCargaTemp[index] = entregaTemp;
                                                    const entregasOrdenadas = reordenarEntregas([...entregasCargaTemp], [...entregasDesCargaTemp]);
                                                    entregasCargaTemp = entregasOrdenadas.carga;
                                                    entregasDesCargaTemp = entregasOrdenadas.descarga;
                                                    setEntregasCarga(entregasCargaTemp);
                                                    setEntregasDesCarga(entregasDesCargaTemp);
                                                  }}></i>
                                              </div>
                                              <div className="col-12" hidden={index >= entregasDesCarga.length - 1}>
                                                <i className="fa fa-caret-down btn" style={{ "font-size": "16px" }}
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title={"Estableser orden " + (index + 2)}
                                                  onClick={() => {
                                                    let entregasCargaTemp = [...entregasCarga];
                                                    let entregasDesCargaTemp = [...entregasDesCarga];
                                                    const entregaTemp = entregasDesCargaTemp[index + 1];
                                                    entregasDesCargaTemp[index + 1] = e;
                                                    entregasDesCargaTemp[index] = entregaTemp;
                                                    const entregasOrdenadas = reordenarEntregas([...entregasCargaTemp], [...entregasDesCargaTemp]);
                                                    entregasCargaTemp = entregasOrdenadas.carga;
                                                    entregasDesCargaTemp = entregasOrdenadas.descarga;
                                                    setEntregasCarga(entregasCargaTemp);
                                                    setEntregasDesCarga(entregasDesCargaTemp);
                                                  }}></i>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-6">{e.orden}</div>
                                        </div>
                                      </div>
                                      <div className="col-1 p-0">
                                        {!hiddenModals ?
                                          <ModalLay
                                            tamanio="85%"
                                            title="Modificar"
                                            name={
                                              <span
                                                className="fa fa-edit"
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title={
                                                  "Modificar (" +
                                                  e.destino +
                                                  ")"
                                                }
                                              ></span>
                                            }
                                            formulario={EntregasNavierasForm}
                                            datosForm={{
                                              entrega: e,
                                              movimiento: currentMovimiento,
                                              idPlataforma: idPlataforma,
                                              setEntregasAMov: setEntregasAMov,
                                              carga: false,
                                              setHiddenModals,
                                            }}
                                          />
                                          : null}
                                      </div>
                                      <div className="col">{e.destino}</div>
                                    </div>
                                  }>
                                  </Form.Group>
                                </Grid.Col>
                              )
                            }
                          </Grid.Row>
                        </Grid.Col>
                        : null}
                      {![952].includes(idPlataforma.value) && idMovimientoTipo.value ?
                        <Grid.Col md={4}>
                          <Form.Group label={
                            <div>
                              {
                                [1, 3].includes(idMovimientoTipo.value) ?
                                  "Origen"
                                  : [2, 4].includes(idMovimientoTipo.value) ?
                                    "Destino"
                                    : "Corredor"
                              }
                              <label className="font-weight-bold text-danger">*</label>
                            </div>}>
                            <Select
                              value={idCorredor}
                              onChange={changeCorredor}
                              options={corredores}
                              isLoading={corredores.length === 0 ? true : false}
                            />
                          </Form.Group>
                        </Grid.Col>
                        : null}
                      {![952].includes(idPlataforma.value) && idMovimientoTipo.value ?
                        <Grid.Col md={4}>
                          <Form.Group label={
                            <div>
                              {
                                [1, 3].includes(idMovimientoTipo.value) ? "Destino" :
                                  [2, 4].includes(idMovimientoTipo.value) ? "Origen" : "Destino"
                              }
                              <label className="font-weight-bold text-danger">*</label>
                            </div>
                          }>
                            {idPlataforma.value && [4].includes(idPlataforma.value) ? (
                              // Campos individuales de dirección cuando el usuario sea valido
                              <>
                                <Grid.Row>
                                  <Grid.Col md={12}>
                                    <Form.Input label="Estado" value={estado} onChange={(e) => setEstado(e.target.value)} />
                                  </Grid.Col>
                                  <Grid.Col md={12}>
                                    <Form.Input label="Municipio" value={municipio} onChange={(e) => setMunicipio(e.target.value)} />
                                  </Grid.Col>
                                  <Grid.Col md={12}>
                                    <Form.Input label="Colonia" value={colonia} onChange={(e) => setColonia(e.target.value)} />
                                  </Grid.Col>
                                  <Grid.Col md={12}>
                                    <Form.Input label="Calle" value={calle} onChange={(e) => setCalle(e.target.value)} />
                                  </Grid.Col>
                                  <Grid.Col md={12}>
                                    <Form.Input label="Número" value={numero} onChange={(e) => setNumero(e.target.value)} />
                                  </Grid.Col>
                                  <Grid.Col md={12}>
                                    <Form.Input label="Código Postal" value={cp} onChange={(e) => setCP(e.target.value)} />
                                  </Grid.Col>
                                </Grid.Row>
                              </>
                            ) : (
                              <Form.Input
                                name="destino"
                                type="text"
                                value={destino}
                                onChange={changeDestino}
                                maxlength="30"
                              />
                            )}
                          </Form.Group>
                        </Grid.Col>
                        : null}
                      {localidades.length ?
                        <Grid.Col md={4}>
                          <Form.Group label={
                            <div>
                              Localidad
                              <label className="font-weight-bold text-danger">*</label>
                            </div>}>
                            <Select
                              value={localidad}
                              onChange={changeLocalidad}
                              options={localidades}
                            />
                          </Form.Group>
                        </Grid.Col> : ""
                      }

                      <Grid.Col md={4}>
                        <Form.Group label={
                          <div>
                            Transportistas
                            <label className="font-weight-bold text-danger">*</label>
                          </div>}>
                          <Grid.Row>
                            <Grid.Col>
                              <Select
                                value={idTransportista}
                                onChange={changeTransportista}
                                options={transportistas}
                                isLoading={
                                  transportistas.length === 0 ? true : false
                                }
                              />
                            </Grid.Col>
                            {idPlataforma.value > 0 &&
                              (isEnable("actualizarTransportista") ||
                                isEnable("agregarTransportista")) ? (
                              <Grid.Col md={2} lg={2}>
                                {isEnable("agregarTransportista") ? (
                                  <span className="float-right">
                                    <ModalLay
                                      tamanio="85%"
                                      title={"Nuevo"}
                                      name={
                                        <span
                                          className="fa fa-plus ml-2"
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title={
                                            "Nuevo Transportista(Antes de crear  valida que no exista)"
                                          }
                                        ></span>
                                      }
                                      formulario={Transportista}
                                      datosForm={{
                                        accion: "nuevo",
                                        elementos: idCliente.data,
                                        setTransportista: setTransportista
                                      }}
                                    />
                                  </span>
                                ) : null}
                                {false && isEnable("actualizarTransportista") ? (
                                  <span className="float-right">
                                    {idTransportista.value > 0 ? (
                                      <ModalLay
                                        tamanio="85%"
                                        title="Modificar"
                                        name={
                                          <span
                                            className="fa fa-edit"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title={
                                              "Modificar (" +
                                              idTransportista.label +
                                              ")"
                                            }
                                          ></span>
                                        }
                                        formulario={TransportistasForm}
                                        datosForm={{
                                          accion: "modificar",
                                          plataforma: idPlataforma.data,
                                          elementos: idTransportista.data,
                                          getfuntion: getfuntionTrans,
                                          stor: stor,
                                        }}
                                      />
                                    ) : null}
                                  </span>
                                ) : null}
                              </Grid.Col>
                            ) : null}
                          </Grid.Row>
                        </Form.Group>
                      </Grid.Col>
                      {idPlataforma.value > 0 ? (
                        [1, 3].includes(idPlataforma.data.plataformaTipo.id) ? (
                          <Grid.Col md={4}>
                            <Form.Group label={
                              <div>
                                Tipo de carga
                                <label className="font-weight-bold text-danger">*</label>
                              </div>}>
                              <Select
                                value={contenedorTipo}
                                onChange={changeContenedorTipo}
                                options={contenedoresTipo}
                                isLoading={
                                  contenedoresTipo.length === 0 ? true : false
                                }
                              />
                            </Form.Group>
                          </Grid.Col>
                        ) : null
                      ) : null}
                      {idPlataforma.value > 0 ? (
                        [1, 3].includes(idPlataforma.data.plataformaTipo.id) ? (
                          <Grid.Col md={4}>
                            <Form.Group label={
                              <div>
                                Tamaño
                                <label className="font-weight-bold text-danger">*</label>
                              </div>}>
                              <Select
                                value={contenedorSize}
                                onChange={changeContenedorSize}
                                options={remolquesTipo}
                              />
                            </Form.Group>
                          </Grid.Col>
                        ) : null
                      ) : null}
                      <Grid.Col md={4}>
                        <Form.Group label={
                          <div>
                            Cita programada (Cliente)
                            <label className="font-weight-bold text-danger">*</label>
                          </div>}>
                          <DateTimePicker
                            format="y-MM-dd h:mm:ss a"
                            value={citaProgramada}
                            onChange={onChangeDate}
                          />
                        </Form.Group>
                      </Grid.Col>
                      {idPlataforma.value && ![1315].includes(idPlataforma.value) ?
                        <Grid.Col md={4}>
                          <Form.Group label={
                            <div>
                              {idPlataforma.value && [4].includes(idPlataforma.value) ? 
                              <div>Cita para colocación de GPS <label className="font-weight-bold text-danger">*</label> </div> : <div>Cita programada (Terminal) <label className="font-weight-bold text-danger">*</label> </div>}
                            </div>}>
                            <DateTimePicker
                              format="y-MM-dd h:mm:ss a"
                              value={citaProgramadaTerminal}
                              onChange={onChangeCitaProgramadaTerminal}
                            />
                          </Form.Group>
                        </Grid.Col>
                        : null}
                      {idPlataforma.value && [4].includes(idPlataforma.value) ?
                        <Grid.Col md={4}>
                          <Form.Group label={
                            <div>
                              Código Postal
                              <label className="font-weight-bold text-danger">*</label>
                            </div>}>
                            <Form.Input placeholder="21000" name="id" max="99999" type="number" onChange={(event) => { setCodigoPostal(event.target.value + "") }} value={codigoPostal} />
                          </Form.Group>
                        </Grid.Col>
                        : null}
                      {idPlataforma.value && [4].includes(idPlataforma.value) && codigoPostal && codigoPostal.length && sinCobertura ?
                        <Alert type="danger" hasExtraSpace>Zona sin cobertura. Se recomienda usar GPS desechable o cuenta espejo.</Alert>
                        : null
                      }
                      {idPlataforma.value > 0 && (
                        <Grid.Col md={4}>
                          <Form.Group label={
                            <div>
                              Tipo de colocación
                              <label className="font-weight-bold text-danger">*</label>
                            </div>}>
                            <Select
                              value={gpsTipoColocacion}
                              onChange={(e) => setGpsTipoColocacion(e)}
                              options={gpsTipoColocaciones}
                            />
                          </Form.Group>
                        </Grid.Col>
                      )}
                      {idPlataforma.value && [4].includes(idPlataforma.value) && gpsTipoColocacion.value && gpsTipoColocacion.data.id === 2 ?
                        <Grid.Col md={4}>
                          <Form.Group label={
                            <div>
                              Tipo de Tecnología
                              <label className="font-weight-bold text-danger">*</label>
                            </div>}>
                            <Select
                              value={tecnologia}
                              onChange={changeTecnologia}
                              options={technologiaOpciones}
                              placeholder="4G Lite"
                            />
                          </Form.Group>
                        </Grid.Col>
                        : null
                      }
                      {/* Si se seleciona CINTURON muestra el mensaje */}
                      {idPlataforma.value && [4].includes(idPlataforma.value) && gpsTipoColocacion.value && gpsTipoColocacion.data.id === 3 ?
                        <Grid.Col md={4}>
                          <div className="font-weight-bold text-danger"> Validar con su ejecutivo de cuenta la disponibilidad de equipo en la localidad </div>
                        </Grid.Col>
                        : null
                      }
                      {/* Si se seleciona CANDADO muestra el mensaje */}
                      {idPlataforma.value && [4].includes(idPlataforma.value) && gpsTipoColocacion.value && gpsTipoColocacion.data.id === 5 ?
                        <Grid.Col md={4}>
                          <div className="font-weight-bold text-danger"> Validar con su ejecutivo de cuenta la disponibilidad de equipo en la localidad </div>
                        </Grid.Col>
                        : null
                      }
                      <Grid.Col md={4}>
                        <Form.Group label={[952].includes(idPlataforma.value) ? "No Referencia" : "Booking"}
                        >
                          <Form.Input name="booking" type="text" value={booking}
                            onChange={(e) => {
                              setBooking(e.target.value);
                              MovimientosObject.booking = e.target.value;
                            }}
                          />
                        </Form.Group>
                      </Grid.Col>
                      {variables.map((v, inex) =>
                        getForm(v.tipo, v.longitud, v.nombre, v.etiqueta)
                      )}
                      <Grid.Col>
                        <Form.Group
                          label={
                            <Form.Label aside={nota.length + "/2000"}>
                              Nota
                            </Form.Label>
                          }
                        >
                          {" "}
                          <Form.Textarea
                            //value={nota}
                            name="nota"
                            onChange={changeNota}
                            placeholder="Nota del movimiento.."
                            rows={6}
                          >{nota}</Form.Textarea>{" "}
                        </Form.Group>{" "}
                      </Grid.Col>
                    </Grid.Row>
                    {idPlataforma.value && [4].includes(idPlataforma.value) ?
                      <Grid.Row>
                        <Grid.Col md={12}>
                          <Form.Group label={
                            <div>
                              Modalidad de Transporte
                              <label className="font-weight-bold text-danger">*</label>
                            </div>}>
                            {modalidades.map((modalidad) => {
                              return <Form.Radio
                                onChange={(e) => {
                                  const mod = modalidades.find(md => md.id == e.target.value)
                                  setModalidadSeleccionada(mod)
                                }}
                                checked={modalidadSeleccionada ? modalidadSeleccionada.id === modalidad.id : false}
                                name="modalidadTransporte"
                                label={
                                  <div className="ml-5">Transporte {modalidad.name}</div>
                                }
                                value={modalidad.id}
                                isInline
                                key={modalidad.id}
                              />
                            })}

                          </Form.Group>
                        </Grid.Col>
                      </Grid.Row>
                      : null
                    }
                    <hr />
                    <Grid.Row>
                      <Grid.Col md={12}>
                        <Form.Group label="¿Generear un movimiento por contenedor?">
                          <Form.Switch
                            name="unMovimientoPorContenedor"
                            checked={unMovimientoPorContenedor}
                            label={unMovimientoPorContenedor ?
                              "Se generara un movimiento por contenedor ingresado" :
                              "Solo se generara un movimiento"}
                            onChange={(e) => { setUnMovimientoPorContenedor(e.target.checked); }}
                          />
                        </Form.Group>
                      </Grid.Col>
                      {[952].includes(idPlataforma.value) &&
                        <Grid.Col md={12}>
                          <Form.Group label={
                            <div class="row">
                              <label for="noPo" class="col-2 col-form-label">No Op</label>
                              <div class="col-2">
                                <Form.Input name="noPo" type="number" value={noPo}
                                  onChange={(e) => {
                                    if (e.target.value > 0 && ([952].includes(idPlataforma.value) ? e.target.value <= 2 : e.target.value < 11)) {
                                      setNoPo(e.target.value);
                                      if (unMovimientoPorContenedor)
                                        setNoContenedores(e.target.value);
                                    }
                                  }}
                                />
                              </div>
                            </div>}
                          >
                            <Grid.Row>
                              {getNoPo()}
                            </Grid.Row>
                          </Form.Group>
                        </Grid.Col>
                      }
                      <Grid.Col md={12}>
                        <Form.Group label={
                          <div class="row">
                            <label for="NoCotenedores" class="col-2 col-form-label">No Contenedores</label>
                            <div class="col-2">
                              <Form.Input name="noContenedores" type="number" value={noContenedores}
                                onChange={(e) => {
                                  if (e.target.value > 0 && ([952].includes(idPlataforma.value) ? e.target.value <= 2 : e.target.value < 11)) {
                                    setNoContenedores(e.target.value);
                                    if (unMovimientoPorContenedor)
                                      setNoPo(e.target.value);
                                  }
                                }}
                              />
                            </div>
                          </div>}
                        >
                          <Grid.Row>
                            {getNoContenedores()}
                          </Grid.Row>
                        </Form.Group>
                      </Grid.Col>
                    </Grid.Row>
                  </Grid.Col>
                </Grid.Row>
                <div className="float-right">
                  {isEnable("actualizar") || isEnable("agregar") ? (<div></div>) : null}
                  <span class="badge">
                    <Button
                      target="_blank"
                      size="sm"
                      RootComponent="a"
                      color="danger"
                      disabled={!crudbutonEnable}
                      onClick={closeNewForm}
                    >
                      <span class="badge">
                        <Icon link={true} name="x" />
                        Cancelar
                      </span>
                    </Button>
                  </span>
                </div>
                <div className="float-right">
                  {isEnable("actualizar") || isEnable("agregar") ? (<div></div>) : null}
                  <span class="badge">
                    <Button
                      target="_blank"
                      size="sm"
                      RootComponent="a"
                      color="primary"
                      disabled={enableSave() || !crudbutonEnable}
                      onClick={guardarMovimiento}
                    >
                      <span class="badge">
                        <Icon link={true} name="save" />
                        Guardar
                      </span>
                    </Button>
                  </span>
                </div>
                {enableSave(false) && [4].includes(idPlataforma.value) && (
                  <div className="float-right">
                    <Alert type="danger" hasExtraSpace>Para continuar, por favor completa todos los campos.</Alert>
                  </div>
                )}
                <br /><br /><br /><br />
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {<LoadStore catalogos={["privilegios", "incidencias"]} />}
      <SiteWrapper>
        <Grid.Col sm={12} lg={12}>

          <div style={{ height: "90vh", "font-size": "smaller" }}>

            {/* <SplitterLayout vertical= {false} percentage= {true}  secondaryInitialSize= {45}> */}
            <div>

              <i className={(hiddenPanel ? "fa fa-reply" : "fa fa-share") + " " + (hiddenPanel ? "text-success" : "text-danger")}
                style={
                  {
                    'font-size': "24px",
                    'float': "right",
                    'position': 'sticky',
                    'top': '0%',
                    'z-index': '1',
                  }
                }
                onClick={() => {
                  for (let c of document.getElementsByClassName("layout-pane")) {
                    if (c.className && c.className === "layout-pane")
                      c.style.width = !hiddenPanel ? "0%" : "40%";
                  }
                  setHiddenPanel(!hiddenPanel);
                }}
              ></i>
              <div className="container bg-light" style={{ height: "100%" }}>
                <Button className="m-5" icon="save" type="submit" onClick={openNewForm}>Crear nueva solicitud</Button>
                <TablePageable
                  titulo={<h3> Solicitudes estatus </h3>}
                  lista={solicitudes}
                  columnas={
                    [
                      {
                        columna: "idSolicitud",
                        label: "Id",
                        enClick: (e) => {
                          setIsFormOpen(true)
                          setCurrentMovimiento(e)
                        }, //enClickDisabled: (e)=> false,//e.idSolicitud === 303? false: true,
                        form: (e) => <i className="fa fa-edit" style={{ "font-size": "24px" }}><p style={{ fontSize: "16px" }}>{e.idSolicitud}</p></i>
                      },
                      { columna: "idMovimiento", label: "idMovimiento" },
                      { columna: "po", label: "Po", splitBy: ";" },
                      { columna: "contenedor", label: "Contenedor", splitBy: ";" },
                      { columna: "contenedorTipo", label: "Tipo contenedor" },
                      { columna: "contenedorSize", label: "Tamaño de Contenedor" },
                      { columna: "idOperacionTipo.nombre", label: "Tipo de operacion" },
                      { columna: "idMovimientoTipo.nombre", label: "Tipotipo de movimiento" },
                      { columna: "idTransportista.nombre", label: "Transportista" },
                      { columna: "idCliente.nombre", label: "Cliente" },
                    ]
                  }
                  id="idSolicitud"
                  //selected={(e) => setCurrentMovimiento(e) }
                  csvname="Solicitudes"
                  resaltarFuncion={(e) => !e.idMovimiento ? true : false}
                  resaltarColor={"rgba(223, 58, 58, 0.47)"}
                />
              </div>
            </div>

            {/* </SplitterLayout> */}
          </div>

        </Grid.Col>
      </SiteWrapper>
    </>
  );
}

function mapStateToProps(state) {
  return {
    privilegios_object: state.catalogos_reducer.privilegios_object,
    incidencias_object: state.catalogos_reducer.incidencias_object,
    localidades_object: state.catalogos_reducer.localidades_object,
  }
}
function mapDispachToProps(dispach) {
  return {
    incidencias_update: (elementos) => dispach(ACTIONS.elementos_incidencias_imput(elementos)),
    privilegios_update: (elementos) => dispach(ACTIONS.elementos_privilegios_imput(elementos)),
  }
}
export default connect(mapStateToProps, mapDispachToProps)(MovimientosNavierosClientePage);
